/**
 * This is the list of Tealium/GA Ecommerce Level Attributes (Product Level Custom Dimensions in terms of GA) from
 * utag_data. We use this names for filtering when we want to track some skus from utag_data but not all of them.
 */
const ecommerceLevelAttributes = [
	'product_name',
	'product_quantity',
	'product_price',
	'product_sku',
	'product_category',
	'product_brand',
	'product_grouping',
	'product_aggregate_price',
	'product_primary_category',
	'product_primary_color',
	'product_primary_finish',
	'product_primary_sku',
	'product_primary_style',
	'product_primary_type',
	'sku_input_type',
	'filter_id',
	'product_islpproduct',
	'product_islpnewproduct',
	'formula_id',
	'test_id',
	'mm_id',
	'testcomposition_id',
	'pin_id',
	'test_start_date',
	'product_gross_margin',
	'product_bullets_count',
	'product_description_length',
	'product_images_count',
	'is_special_value',
	'product_sale_type'
];

export default ecommerceLevelAttributes;
